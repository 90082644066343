import React, { useState, useEffect } from 'react'
import {fetchAllVolunteers} from "../services/api";
import DefaultLayout from "../layouts/DefaultLayout";
import {Link} from "gatsby";

function ProfileSnapshot({data}) {
  return <div className='col-sm-6 col-md-3' style={{fontFamily: '"fira sans", sans-serif'}}>
    <div style={{paddingBottom: '20px'}}><img src={`${process.env.GATSBY_API_URL}/v1/media/` + data.profilePhotoId} alt={data.firstName + ' ' + data.lastName} width='208' height='208' style={{objectFit: 'cover'}}/></div>
    <div><b>{data.firstName} {data.lastName}</b></div>
    <div>{data.city}, {data.country}</div>
    <Link to={'/volunteer/' + data.volunteerId}>Μάθε Περισσότερα</Link>
  </div>
}

const crumbs = [
  {
    title: 'Αρχική',
    url: '/'
  },
  {
    title: 'Οι Εθελοντές Μας'
  }];

export default function VolunteerProfiles() {

  const [data, setData] = useState({});

  useEffect(() => {
    fetchAllVolunteers()
      .then(res => res.json())
      .then(response => {
        setData({
          linesOfEducation: response.linesOfEducation,
          volunteers: response.volunteers
        })
      })
      .catch((error) => {
        setData({
          error: 'Failed to read latest profiles',
        })
      })
  }, []);

  return <DefaultLayout title='Οι Εθελοντές Μας' crumbs={crumbs}>
    <div className='row content-section'>
    {
      data.volunteers &&
      data.volunteers.map(volunteer => <ProfileSnapshot key={volunteer.volunteerId} data={volunteer} />)
    }
    </div>
  </DefaultLayout>
}
